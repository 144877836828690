import React, { useCallback, useEffect, useState } from 'react';
import { FansFundMeProfileScreen } from '@fans-fund-me/storybook';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkProps } from './LinkProps';
import { arrayMoveImmutable } from 'array-move';
import axios from '../../api/axios';
import useAuth from '../../contexts/UseAuth';
// import Item from '@fans-fund-me/storybook/build/stories/fans-fund-me/types/Item';
import { ItemProps as Item } from './ItemProps';
import { getWishlistByUsername } from '../../api/wishlist';
import { toast } from 'react-toastify';
import TOAST_MESSAGES from '../../utilities/toast-messages';
import currencies from '../../utilities/currencies.json';
import ProfileLink from '@fans-fund-me/storybook/build/dist/stories/fans-fund-me/types/ProfileLink';

export function Profile({
  username,
}: {
  username: string;
}): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [profileCurrency, setProfileCurrency] = useState('USD');

  const [isProfileLoaded, setIsProfileLoaded] = useState(false);

  const wishlist: Item[] = [];
  const subscriptions: Item[] = [];
  const links: LinkProps[] = [];

  const [profileUserID, setProfileUserID] = useState('');

  const [items, setItems] = useState(wishlist);
  const [subscriptionItems, setSubscriptionItems] = useState(subscriptions);
  const [editableLinks, setEditableLinks] = useState(links);
  const [isModalVisible, setIsModalVisible] = useState<boolean | 'crop-image'>(
    false
  );
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState<
    boolean | 'item' | 'subscription' | 'profile' | 'links' | 'surprise-gift'
  >(false);
  const [itemID, setItemID] = useState('');
  const [itemURL, setItemURL] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemPicture, setItemPicture] = useState(
    `https://fans-fund-me-core-user-images-${process.env.REACT_APP_ENVIRONMENT}.s3.eu-west-2.amazonaws.com/wishlist/FFM-default-wishlist-item.jpg`
  );
  const [itemPictureUpload, setItemPictureUpload] = useState<string>('');
  const [itemPrice, setItemPrice] = useState<string>('');
  const [itemFrequency, setItemFrequency] = useState<
    | {
        label: string | undefined;
        value: string | undefined;
      }
    | undefined
  >(undefined);
  const [editUsername, setUsername] = useState(
    username !== undefined ? username : ''
  );
  const [editDisplayName, setDisplayName] = useState('');
  const [editBio, setBio] = useState('');
  const [linkID, setLinkID] = useState('');
  const [linkURL, setLinkURL] = useState('');
  const [linkLabel, setLinkLabel] = useState('');
  const [allowRepeatPurchases, setallowRepeatPurchases] =
    useState<boolean>(false);
  const [selectedCoverImage, setSelectedCoverImage] = useState('');
  const [selectedProfilePicture, setSelectedProfilePicture] = useState('');

  const [userCurrency, setUserCurrency] = useState('');

  const [surpriseGiftId, setSurpriseGiftId] = useState('');
  const [surpriseGiftExchangeRate, setSurpriseGiftExchangeRate] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [scrapeIsLoading, setScrapeIsLoading] = useState(false);

  const [itemURLError, setItemURLError] = useState('');
  const [itemNameError, setItemNameError] = useState('');
  const [itemPictureError, setItemPictureError] = useState('');
  const [itemPriceError, setItemPriceError] = useState('');
  const [itemFrequencyError, setItemFrequencyError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [bioError, setBioError] = useState('');
  const [linkURLError, setLinkURLError] = useState('');
  const [linkLabelError, setLinkLabelError] = useState('');
  const [surpriseGiftError, setSurpriseGiftError] = useState('');

  const [profileDisplayName, setProfileDisplayName] = useState('');
  const [profileBio, setProfileBio] = useState('');

  const [profileIsEditable, setProfileIsEditable] = useState(false);
  const [isSurpriseGiftEnabled, setIsSurpriseGiftEnabled] = useState(false);
  const [isAcceptingPayments, setIsAcceptingPayments] = useState(true);
  const [surpriseGiftAmount, setSurpriseGiftAmount] = useState('');
  const [surpriseGiftReason, setSurpriseGiftReason] = useState('');
  const [surpriseGiftConvertedAmount, setSurpriseGiftConvertedAmount] =
    useState('');
  const [surpriseGiftMinimumAmount, setSurpriseGiftMinimumAmount] = useState<
    string | undefined
  >(undefined);

  const [stencilWidth, setStencilWidth] = useState<number>(300);
  const [stencilHeight, setStencilHeight] = useState<number>(300);
  const [imageToCrop, setImageToCrop] = useState<string>('');
  const [croppedImageURL, setCroppedImageURL] = useState<string | Blob>('');
  const [uploadingImage, setUploadingImage] = useState('');
  const [cropImageError, setCropImageError] = useState('');
  const [wishlistLoadError, setWishlistLoadError] = useState(false);

  const authUser = auth.authUser;

  const setSurpriseGiftAmountHandler = (amount: string) => {
    const regex = new RegExp('^(\\d+(\\.\\d{0,2})?)?$');
    if (regex.test(amount)) {
      if (userCurrency !== profileCurrency) {
        const convertedAmount = surpriseGiftExchangeRate * Number(amount);
        setSurpriseGiftConvertedAmount(convertedAmount.toString());
        setSurpriseGiftAmount(amount);
      } else {
        setSurpriseGiftAmount(amount);
      }

      setSurpriseGiftError('');
    } else {
      setSurpriseGiftError(
        'The price should only contain numbers and decimals up to two decimal places'
      );
    }
  };

  // const blobToBase64 = (blob: Blob): Promise<string> => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   return new Promise((resolve) => {
  //     reader.onloadend = () => {
  //       if (typeof reader.result === 'string') {
  //         const str = reader.result.substring(
  //           reader.result.indexOf('base64,') + 7
  //         );
  //         resolve(str);
  //       }
  //     };
  //   });
  // };

  const chooseNewCoverImage = (picture: Blob) => {
    setStencilWidth(600);
    setStencilHeight(180);
    setImageToCrop(URL.createObjectURL(picture));
    setIsModalVisible('crop-image');
    setUploadingImage('cover-image');
    setCropImageError('');
  };

  const uploadCoverImage = (croppedImageDataURL: string) => {
    const id = toast.loading(TOAST_MESSAGES.IMAGE_UPLOADING('cover image'));
    const coverImage = croppedImageDataURL.substring(
      croppedImageDataURL.indexOf('base64,') + 7
    );

    if (coverImage === '') {
      setCropImageError('Please change the zoom level on the image');
      toast.update(id, {
        render: TOAST_MESSAGES.IMAGE_ERROR('cover image'),
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });

      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          coverImage: coverImage,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        setSelectedCoverImage(croppedImageDataURL);
        setIsModalVisible(false);
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_SUCCESS('Cover image'),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setCropImageError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'coverImage'
          )?.message
        );
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_ERROR('cover image'),
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const chooseNewProfilePicture = (picture: Blob) => {
    setStencilWidth(300);
    setStencilHeight(300);
    setImageToCrop(URL.createObjectURL(picture));
    setIsModalVisible('crop-image');
    setUploadingImage('profile-picture');
    setCropImageError('');
  };

  const uploadProfilePicture = (croppedImageDataURL: string) => {
    const id = toast.loading(TOAST_MESSAGES.IMAGE_UPLOADING('profile picture'));
    const profilePicture = croppedImageDataURL.substring(
      croppedImageDataURL.indexOf('base64,') + 7
    );

    if (profilePicture === '') {
      setCropImageError('Please change the zoom level on the image');
      toast.update(id, {
        render: TOAST_MESSAGES.IMAGE_ERROR('profile picture'),
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });

      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          profilePic: profilePicture,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        setSelectedProfilePicture(croppedImageDataURL);
        setIsModalVisible(false);
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_SUCCESS('Profile picture'),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setCropImageError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'profilePic'
          )?.message
        );
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_ERROR('profile picture'),
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  // const uploadItemPicture = (picture: Blob | string) => {
  //   if (typeof picture !== 'string') {
  //     blobToBase64(picture).then((res) => {
  //       setItemPicture(URL.createObjectURL(picture));
  //       setItemPictureUpload(res);
  //     });
  //   }
  // };

  const chooseNewItemImage = (picture: Blob) => {
    setStencilWidth(225);
    setStencilHeight(225);
    setImageToCrop(URL.createObjectURL(picture));
    setIsModalVisible('crop-image');
    setUploadingImage('item-image');
    setCropImageError('');
  };

  const setItemImage = (croppedImageDataURL: string) => {
    const itemImage = croppedImageDataURL.substring(
      croppedImageDataURL.indexOf('base64,') + 7
    );

    if (itemImage === '') {
      setCropImageError('Please change the zoom level on the image');
      return;
    }

    setItemPicture(croppedImageDataURL);
    setItemPictureUpload(itemImage);
    setIsModalVisible(false);
  };

  const uploadImage = (croppedImageDataURL: string) => {
    switch (uploadingImage) {
      case 'profile-picture':
        uploadProfilePicture(croppedImageDataURL);
        break;

      case 'cover-image':
        uploadCoverImage(croppedImageDataURL);
        break;

      case 'item-image':
        setItemImage(croppedImageDataURL);
        break;

      default:
        break;
    }
  };

  const getWishlist = useCallback(
    (isUserLoggedInOwnAcc: boolean, currency?: string) => {
      let curr: string | undefined =
        currency === undefined ? userCurrency : currency;
      if (isUserLoggedInOwnAcc) {
        curr = undefined;
      }
      getWishlistByUsername(username!, curr)
        .then((response) => {
          setItems(response.data.items);
          setIsLoading(false);
        })
        .catch(() => {
          setWishlistLoadError(true);
          setIsLoading(false);
        });
    },
    [username, userCurrency]
  );

  // const getSubscriptions = useCallback(
  //   (currency?: string) => {
  //     // const fetchSubscriptionstLast = onlyResolvesLast(
  //     //   getSubscriptionsByUsername
  //     // );
  //     const curr = currency === undefined ? userCurrency : currency;
  //     getSubscriptionsByUsername(username!, curr)
  //       .then((response) => {
  //         setSubscriptionItems(response.data.items);
  //       })
  //       .catch((err) => console.log(err));
  //   },
  //   [username, userCurrency]
  // );

  // const getLinks = useCallback(() => {
  //   getLinksByUsername(username!)
  //     .then((response) => {
  //       setEditableLinks(response.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [username]);

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);

    getWishlist(false, currency);
    // getSubscriptions(currency);
  };

  useEffect(() => {
    const currency = localStorage.getItem('ffm_currency');

    if (currency !== null) {
      setUserCurrency(currency);
    } else {
      axios
        .get('https://ipapi.co/currency/')
        .then((response) => {
          if (currencies.includes(response.data)) {
            localStorage.setItem('ffm_currency', response.data);
            setUserCurrency(response.data);
          } else {
            localStorage.setItem('ffm_currency', 'USD');
            setUserCurrency('USD');
          }
        })
        .catch(() => {
          localStorage.setItem('ffm_currency', 'USD');
          setUserCurrency('USD');
        });
    }
  }, [setUserCurrency]);

  const saveWishlistItem = () => {
    setIsLoading(true);

    const request: {
      id?: string;
      name: string;
      price: string;
      allowMultiplePurchases: boolean;
      image?: string;
    } = {
      name: itemName,
      price: itemPrice.toString(),
      allowMultiplePurchases: allowRepeatPurchases,
    };

    if (itemPictureUpload !== '') request.image = itemPictureUpload;

    if (itemID === '') {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/wishlist`,
          request,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);

          const item: Item = {
            id: response.data.id,
            name: response.data.name,
            image: response.data.image,
            price: response.data.price,
            subscription: false,
            // allowRepeatPurchases: response.data.allowMultiplePurchases,
            allowMultiplePurchases: response.data.allowMultiplePurchases,
            frequency: '',
            editable: false,
            gift: false,
          };

          const wishlistItems = [...items];
          wishlistItems.push(item);

          setItems(wishlistItems);
          setIsBottomSheetVisible(false);
        })
        .catch((error) => {
          setIsLoading(false);

          setItemNameError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'name'
            )?.message
          );
          setItemPictureError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'image'
            )?.message
          );
          setItemPriceError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'price'
            )?.message
          );
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/wishlist/${itemID}`,
          request,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          // const item: Item = {
          //   id: response.data.id,
          //   name: response.data.name,
          //   image: response.data.image,
          //   price: response.data.price,
          //   subscription: false,
          //   allowRepeatPurchases: response.data.allowMultiplePurchases,
          //   allowMultiplePurchases: response.data.allowMultiplePurchases,
          //   frequency: '',
          //   editable: false,
          //   gift: false,
          // };

          // const wishlistItems = [...items];
          // const index = wishlistItems.findIndex((it) => it.id === itemID);
          // wishlistItems[index] = item;

          // setItems(wishlistItems);

          setItems(response.data.items);
          setIsBottomSheetVisible(false);
        })
        .catch((error) => {
          setIsLoading(false);

          setItemNameError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'name'
            )?.message
          );
          setItemPictureError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'image'
            )?.message
          );
          setItemPriceError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'price'
            )?.message
          );
        });
    }
  };

  // const saveSubscriptionItem = () => {
  //   let itemFre = '';
  //   if (itemFrequency !== undefined && itemFrequency.value !== undefined) {
  //     itemFre = itemFrequency.value;
  //   }

  //   const request: {
  //     id?: string;
  //     name: string;
  //     price: string;
  //     frequency: string;
  //     allowMultiplePurchases: boolean;
  //     image?: unknown;
  //   } = {
  //     name: itemName,
  //     price: itemPrice.toString(),
  //     frequency: itemFre,
  //     allowMultiplePurchases: allowRepeatPurchases,
  //   };

  //   if (itemPictureUpload !== '') request.image = itemPictureUpload;

  //   if (itemID === '') {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/subscriptions`,
  //         request,
  //         {
  //           headers: { Authorization: `Bearer ${auth.token}` },
  //         }
  //       )
  //       .then((response) => {
  //         const item: Item = {
  //           id: response.data.id,
  //           name: response.data.name,
  //           image: response.data.image,
  //           price: response.data.price,
  //           subscription: true,
  //           allowRepeatPurchases: response.data.allowMultiplePurchases,
  //           allowMultiplePurchases: response.data.allowMultiplePurchases,
  //           frequency: response.data.frequency,
  //           editable: false,
  //           gift: false,
  //         };

  //         const subItems = [...subscriptionItems];
  //         subItems.push(item);

  //         setSubscriptionItems(subItems);
  //         setIsModalVisible(false);
  //       })
  //       .catch((error) => {
  //         setItemNameError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'name'
  //           )?.message
  //         );
  //         setItemPictureError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'image'
  //           )?.message
  //         );
  //         setItemPriceError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'price'
  //           )?.message
  //         );
  //         setItemFrequencyError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'frequency'
  //           )?.message
  //         );
  //       });
  //   } else {
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/subscriptions/${itemID}`,
  //         request,
  //         {
  //           headers: { Authorization: `Bearer ${auth.token}` },
  //         }
  //       )
  //       .then((response) => {
  //         const item: Item = {
  //           id: response.data.id,
  //           name: response.data.name,
  //           image: response.data.image,
  //           price: response.data.price,
  //           subscription: true,
  //           allowRepeatPurchases: response.data.allowMultiplePurchases,
  //           allowMultiplePurchases: response.data.allowMultiplePurchases,
  //           frequency: response.data.frequency,
  //           editable: false,
  //           gift: false,
  //         };

  //         const subItems = [...subscriptionItems];
  //         const index = subItems.findIndex((it) => it.id === itemID);
  //         subItems[index] = item;

  //         setSubscriptionItems(subItems);
  //         setIsModalVisible(false);
  //       })
  //       .catch((error) => {
  //         setItemNameError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'name'
  //           )?.message
  //         );
  //         setItemPictureError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'image'
  //           )?.message
  //         );
  //         setItemPriceError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'price'
  //           )?.message
  //         );
  //         setItemFrequencyError(
  //           error.response.data.fields?.find(
  //             (field: { fieldName: string; message: string }) =>
  //               field.fieldName === 'frequency'
  //           )?.message
  //         );
  //       });
  //   }
  // };

  const saveProfile = () => {
    setIsLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          username: editUsername,
          displayName: editDisplayName,
          bio: editBio,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        setIsLoading(false);
        setIsBottomSheetVisible(false);
        setProfileDisplayName(editDisplayName);
        setProfileBio(editBio);

        if (editUsername !== username) {
          navigate(`/${editUsername}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        setUsernameError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'username'
          )?.message
        );
        setDisplayNameError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'displayName'
          )?.message
        );
      });
  };

  const saveLink = () => {
    setIsLoading(true);
    if (linkID === '') {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/links`,
          {
            url: linkURL,
            label: linkLabel,
          },
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);

          const newLink = {
            id: response.data.id,
            label: response.data.label,
            url: response.data.url,
          };
          const newLinks = [...editableLinks];
          newLinks.push(newLink);

          setEditableLinks(newLinks);
          setIsBottomSheetVisible(false);
        })
        .catch((error) => {
          setIsLoading(false);

          setLinkURLError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'url'
            )?.message
          );
          setLinkLabelError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'label'
            )?.message
          );
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/links/${linkID}`,
          {
            url: linkURL,
            label: linkLabel,
          },
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then(() => {
          setIsLoading(false);

          const newLink = {
            id: linkID,
            label: linkLabel,
            url: linkURL,
          };
          const newLinks = [...editableLinks];
          const index = newLinks.findIndex((it) => it.id === linkID);
          newLinks[index] = newLink;

          setEditableLinks(newLinks);
          setIsBottomSheetVisible(false);
        })
        .catch((error) => {
          setIsLoading(false);

          setLinkURLError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'url'
            )?.message
          );
          setLinkLabelError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'label'
            )?.message
          );
        });
    }
  };

  const deleteWishlistItem = () => {
    setIsLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/wishlist/${itemID}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        setIsLoading(false);
        const index = items.findIndex((item) => item.id === itemID);

        const wishlistItems = [...items];
        wishlistItems.splice(index, 1);

        setItems(wishlistItems);
        setIsBottomSheetVisible(false);
      })
      .catch((err) => setIsLoading(false));
  };

  // const deleteSubscriptionItem = () => {
  //   axios
  //     .delete(
  //       `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/subscriptions/${itemID}`,
  //       { headers: { Authorization: `Bearer ${auth.token}` } }
  //     )
  //     .then(() => {
  //       const index = subscriptionItems.findIndex((item) => item.id === itemID);

  //       const newSubscriptionItems = [...subscriptionItems];
  //       newSubscriptionItems.splice(index, 1);

  //       setSubscriptionItems(newSubscriptionItems);
  //       setIsModalVisible(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const deleteLink = () => {
    setIsLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/links/${linkID}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then(() => {
        setIsLoading(false);
        const index = editableLinks.findIndex((item) => item.id === linkID);

        const newLinks = [...editableLinks];
        newLinks.splice(index, 1);

        setEditableLinks(newLinks);
        setIsBottomSheetVisible(false);
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    if (!isModalVisible) {
      setUploadingImage('');
      setCropImageError('');
    }

    if (!isBottomSheetVisible) {
      setUsername(username !== undefined ? username : '');
      setDisplayName(profileDisplayName);
      setBio(profileBio);

      setItemID('');
      setItemURL('');
      setItemName('');
      setItemPicture(
        `https://fans-fund-me-core-user-images-${process.env.REACT_APP_ENVIRONMENT}.s3.eu-west-2.amazonaws.com/wishlist/FFM-default-wishlist-item.jpg`
      );
      setItemPictureUpload('');
      setItemPrice('');
      setItemFrequency(undefined);
      setallowRepeatPurchases(false);

      setSurpriseGiftAmount('');
      setSurpriseGiftReason('');
      setSurpriseGiftConvertedAmount('0');

      setLinkID('');
      setLinkLabel('');
      setLinkURL('');

      setItemNameError('');
      setItemPictureError('');
      setItemPriceError('');
      setItemFrequencyError('');
      setUsernameError('');
      setDisplayNameError('');
      setLinkURLError('');
      setLinkLabelError('');
    }
  }, [
    isModalVisible,
    isBottomSheetVisible,
    username,
    profileDisplayName,
    profileBio,
  ]);

  // useEffect(() => {
  // HERE
  // }, [userCurrency]);

  const handleDragEndWishlist: (event: any) => void = (event: any) => {
    const { active, over } = event;
    let updatedItem;
    let updatedItemIndex;

    if (active.id !== over?.id) {
      setItems((itemz) => {
        const oldIndex = itemz.map((e) => e.id).indexOf(String(active.id));
        const newIndex = itemz.map((e) => e.id).indexOf(String(over?.id));

        updatedItem = active.id;
        updatedItemIndex = newIndex;

        return arrayMoveImmutable(itemz, oldIndex, newIndex);
      });

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/wishlist/${updatedItem}`,
          {
            index: updatedItemIndex,
          },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        )
        .catch((err) => {
          toast.error('There was an error updating your wishlist item');
        });
    }
  };

  const handleDragEndSubscriptions: (event: any) => void = (event: any) => {
    const { active, over } = event;
    let updatedItem;
    let updatedItemIndex;

    if (active.id !== over?.id) {
      setSubscriptionItems((itemz) => {
        const oldIndex = itemz.map((e) => e.id).indexOf(String(active.id));
        const newIndex = itemz.map((e) => e.id).indexOf(String(over?.id));

        updatedItem = active.id;
        updatedItemIndex = newIndex;

        return arrayMoveImmutable(itemz, oldIndex, newIndex);
      });

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/subscriptions/${updatedItem}`,
          {
            index: updatedItemIndex,
          },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        )
        .catch(() => {
          toast.error('There was an error updating your subscription item');
        });
    }
  };

  const handleDragEndLinks: (event: any) => void = (event: any) => {
    const { active, over } = event;
    let updatedItem;
    let updatedItemIndex;

    if (active.id !== over?.id) {
      setEditableLinks((itemz) => {
        const oldIndex = itemz.map((e) => e.id).indexOf(String(active.id));
        const newIndex = itemz.map((e) => e.id).indexOf(String(over?.id));

        updatedItem = active.id;
        updatedItemIndex = newIndex;

        return arrayMoveImmutable(itemz, oldIndex, newIndex);
      });

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}/links/${updatedItem}`,
          {
            index: updatedItemIndex,
          },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        )
        .catch(() => {
          toast.error('There was an error updating your profile link');
        });
    }
  };

  const checkout = (item: Item) => {
    localStorage.removeItem('ffm_checkout_user');
    localStorage.removeItem('ffm_checkout_username');
    localStorage.removeItem('ffm_checkout_item');
    localStorage.removeItem('ffm_checkout_surprise_gift');
    localStorage.removeItem('ffm_checkout_surprise_gift_amount');
    localStorage.removeItem('ffm_checkout_surprise_gift_id');
    localStorage.removeItem('ffm_checkout_surprise_gift_message');

    localStorage.setItem('ffm_checkout_user', profileUserID);
    localStorage.setItem('ffm_checkout_username', username!);
    localStorage.setItem('ffm_checkout_item', item.id);
    navigate('/checkout');
  };

  const handleCheckoutSurpriseGift = () => {
    const axiosConfig =
      auth.token === null
        ? {}
        : { headers: { Authorization: `Bearer ${auth.token}` } };

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/gift/${surpriseGiftId}`,
        {
          id: surpriseGiftId,
          suggestion: surpriseGiftReason,
          senderAmount: surpriseGiftAmount,
        },
        axiosConfig
      )
      .then((response) => {
        const paymentId = response.data.singlePaymentId;

        localStorage.removeItem('ffm_checkout_user');
        localStorage.removeItem('ffm_checkout_item');
        localStorage.removeItem('ffm_checkout_surprise_gift');
        localStorage.removeItem('ffm_checkout_surprise_gift_amount');
        localStorage.removeItem('ffm_checkout_surprise_gift_id');
        localStorage.removeItem('ffm_checkout_surprise_gift_payment_id');
        localStorage.removeItem('ffm_checkout_surprise_gift_message');

        localStorage.setItem('ffm_checkout_user', profileUserID);
        localStorage.setItem('ffm_checkout_username', username!);
        localStorage.setItem('ffm_checkout_surprise_gift', 'true');
        localStorage.setItem('ffm_checkout_surprise_gift_id', surpriseGiftId);
        localStorage.setItem(
          'ffm_checkout_surprise_gift_payment_id',
          paymentId
        );
        localStorage.setItem(
          'ffm_checkout_surprise_gift_amount',
          surpriseGiftAmount
        );
        localStorage.setItem(
          'ffm_checkout_surprise_gift_message',
          surpriseGiftReason
        );
        navigate('/checkout');
      })
      .catch((error) => {
        setSurpriseGiftError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'senderAmount'
          )?.message
        );
      });
  };

  const itemPriceValidation = (price: string) => {
    const regex = new RegExp('^(\\d+(\\.\\d{0,2})?)?$');
    if (regex.test(price)) {
      setItemPrice(price);
      setItemPriceError('');
    } else {
      setItemPriceError(
        'The item price should only contain numbers and decimals up to two decimal places'
      );
    }
  };

  const editItem = (item: Item) => {
    setItemID(item.id);
    setItemPicture(item.image);
    setItemName(item.name);
    itemPriceValidation(item.price);
    setIsBottomSheetVisible('item');
    if (item.allowMultiplePurchases !== undefined) {
      setallowRepeatPurchases(item.allowMultiplePurchases);
    }
  };

  // const editSubscriptionItem = (item: Item) => {
  //   setItemID(item.id);
  //   setItemPicture(item.image);
  //   setItemName(item.name);
  //   setItemPrice(item.price);
  //   setItemFrequency({
  //     label: item.frequency.charAt(0).toUpperCase() + item.frequency.slice(1),
  //     value: item.frequency,
  //   });
  //   setIsModalVisible('subscription');
  //   if (item.allowMultiplePurchases !== undefined) {
  //     setallowRepeatPurchases(item.allowMultiplePurchases);
  //   }
  // };

  useEffect(() => {
    // const canceled = false;

    if (!isProfileLoaded && userCurrency !== '') {
      setIsLoading(true);
      setIsProfileLoaded(true);

      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${username}?byUsername=true`,
          { headers: { Authorization: `Bearer ${auth.token}` }, timeout: 5000 }
        )
        .then((response) => {
          console.log(response);
          const userID = localStorage.getItem('ffm_user_id');

          if (userID === response.data.userId) {
            if (response.data.email === undefined) {
              auth.logout();
            }
            setProfileIsEditable(true);

            if (response.data.currency !== userCurrency) {
              localStorage.setItem('ffm_currency', response.data.currency);
              setUserCurrency(response.data.currency);
            }
          }

          setProfileUserID(response.data.userId);
          setDisplayName(response.data.displayName);
          setSelectedCoverImage(response.data.coverImage);
          setSelectedProfilePicture(response.data.profilePic);
          setBio(response.data.bio);
          setProfileDisplayName(response.data.displayName);
          setProfileBio(response.data.bio);
          setProfileCurrency(response.data.currency);
          setIsSurpriseGiftEnabled(response.data.enableSurpriseGifts);
          setSurpriseGiftMinimumAmount(response.data.surpriseGiftsMinimum);
          setIsAcceptingPayments(response.data.acceptingPayments);
          setEditableLinks(response.data.links);

          // if (!canceled) {
          // getLinks();
          getWishlist(userID === response.data.userId);
          // getSubscriptions();
          // }
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            localStorage.setItem('ffm_not_found_username', username!);
            navigate('/not-found');
          } else if (err.code === 'ECONNABORTED') {
            localStorage.setItem(
              'ffm_server_error_redirected_from',
              location.pathname
            );
            navigate('/server-error');
          } else {
            localStorage.setItem(
              'ffm_server_error_redirected_from',
              location.pathname
            );
            navigate('/server-error');
          }
        });
    }

    // return () => {
    //   canceled = true;
    // };
  }, [
    auth,
    username,
    navigate,
    getWishlist,
    location.pathname,
    isProfileLoaded,
    userCurrency,
  ]);

  useEffect(() => {
    if (isBottomSheetVisible === 'surprise-gift') {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/gift`, {
          receiverId: profileUserID,
          currency: userCurrency,
        })
        .then((response) => {
          setSurpriseGiftId(response.data.id);
          setSurpriseGiftExchangeRate(response.data.exchangeRate);
        })
        .catch(() => {
          toast.error(
            'There was an error retrieving Surprise Gift information'
          );
        });
    }
  }, [isBottomSheetVisible, profileUserID, userCurrency]);

  const clickWishlistItem = !profileIsEditable ? checkout : editItem;

  const onClickEditProfileLink = (link: ProfileLink) => {
    setLinkID(link.id);
    setLinkLabel(link.label);
    setLinkURL(link.url);
    setIsBottomSheetVisible('links');
  };
  // const clickSubscriptionItem = !profileIsEditable
  //   ? checkout
  //   : editSubscriptionItem;

  const scrapeOpenGraph = () => {
    // TODO - BACKEND
    // const url =
    //   'https://www.nike.com/gb/t/air-force-1-07-shoes-jPzQdm/DR0155-001';
    // axios.get(url).then((res) => console.log(res));
  };

  return (
    <FansFundMeProfileScreen
      navbarItems={[]}
      acceptedCurrencies={currencies}
      coverImage={selectedCoverImage}
      profilePicture={selectedProfilePicture}
      displayName={editDisplayName}
      bio={editBio}
      wishlist={items}
      links={editableLinks}
      subscriptions={subscriptionItems}
      authUser={authUser}
      isEditable={profileIsEditable}
      onClickWishlistItem={clickWishlistItem}
      onClickSubscriptionItem={() => {}}
      onClickSaveWishlistItem={saveWishlistItem}
      onClickSaveSubscriptionItem={Function}
      onClickSaveProfile={saveProfile}
      onClickSaveLink={saveLink}
      onClickDeleteWishlistItem={deleteWishlistItem}
      onClickDeleteSubscriptionItem={() => {}}
      onClickEditProfileLink={onClickEditProfileLink}
      onClickDeleteLink={deleteLink}
      itemID={itemID}
      setItemID={setItemID}
      itemURL={itemURL}
      setItemURL={setItemURL}
      itemName={itemName}
      setItemName={setItemName}
      itemPicture={itemPicture}
      setItemPicture={chooseNewItemImage}
      itemFrequency={itemFrequency}
      setItemFrequency={(label, value) =>
        setItemFrequency({ label: label, value: value })
      }
      itemPrice={itemPrice}
      setItemPrice={itemPriceValidation}
      allowRepeatPurchases={allowRepeatPurchases}
      setAllowRepeatPurchases={setallowRepeatPurchases}
      editUsername={editUsername}
      setUsername={setUsername}
      editDisplayName={editDisplayName}
      setDisplayName={setDisplayName}
      editBio={editBio}
      setBio={setBio}
      linkID={linkID}
      setLinkID={setLinkID}
      linkURL={linkURL}
      setLinkURL={setLinkURL}
      linkLabel={linkLabel}
      setLinkLabel={setLinkLabel}
      isBottomSheetVisible={isBottomSheetVisible}
      setIsBottomSheetVisible={setIsBottomSheetVisible}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      setSelectedCoverImage={chooseNewCoverImage}
      // uploadCoverImage={uploadProfilePicture}
      surpriseGiftMinimumAmount={surpriseGiftMinimumAmount}
      setSelectedProfilePicture={chooseNewProfilePicture}
      uploadProfilePicture={uploadImage}
      handleDragEndWishlist={handleDragEndWishlist}
      handleDragEndSubscriptions={handleDragEndSubscriptions}
      handleDragEndLinks={handleDragEndLinks}
      handleScrapeURL={scrapeOpenGraph}
      currency={profileCurrency}
      userCurrency={userCurrency}
      setUserCurrency={updateUserCurrency}
      isSurpriseGiftEnabled={isSurpriseGiftEnabled}
      surpriseGiftAmount={surpriseGiftAmount}
      setSurpriseGiftAmount={setSurpriseGiftAmountHandler}
      surpriseGiftReason={surpriseGiftReason}
      setSurpriseGiftReason={setSurpriseGiftReason}
      surpriseGiftError={surpriseGiftError}
      surpriseGiftConvertedAmount={surpriseGiftConvertedAmount}
      handleCheckoutSurpriseGift={handleCheckoutSurpriseGift}
      isLoading={isLoading}
      scrapeIsLoading={scrapeIsLoading}
      itemURLError={itemURLError}
      itemNameError={itemNameError}
      itemPictureError={itemPictureError}
      itemPriceError={itemPriceError}
      itemFrequencyError={itemFrequencyError}
      usernameError={usernameError}
      displayNameError={displayNameError}
      bioError={bioError}
      linkURLError={linkURLError}
      linkLabelError={linkLabelError}
      isAcceptingPayments={isAcceptingPayments}
      stencilWidth={stencilWidth}
      stencilHeight={stencilHeight}
      imageURL={imageToCrop}
      setCroppedImageURL={setCroppedImageURL}
      cropImageError={cropImageError}
      wishlistLoadError={wishlistLoadError}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
}
