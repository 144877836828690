import React, { useCallback, useEffect } from 'react';
import AuthContext from './AuthContext';
import axios from '../api/axios';
import { toast } from 'react-toastify';

type AuthContextProps = {
  children: React.ReactNode;
};

function AuthContextProvider({ children }: AuthContextProps) {
  const [token, setToken] = React.useState<string | null>(
    localStorage.getItem('ffm_token')
  );
  const [userID, setUserID] = React.useState<string | null>(
    localStorage.getItem('ffm_user_id')
  );
  const [username, setUsername] = React.useState<string | null>(
    localStorage.getItem('ffm_username')
  );
  const [currency, setCurrency] = React.useState<string | null>(
    localStorage.getItem('ffm_currency')
  );
  const [isStripeSetupComplete, setIsStripeSetupComplete] =
    React.useState<boolean>(true);
  const [notAcceptingPaymentsReasons, setNotAcceptingPaymentsReasons] =
    React.useState<string[]>([]);
  const [isFirstPageLoad, setIsFirstPageLoad] = React.useState(true);
  const [cookies, setCookies] = React.useState<string | null>(
    localStorage.getItem('ffm_cookies')
  );

  const getStripeLink = useCallback(() => {
    const id = toast.loading('Sending you to Stripe...');

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/account/`,
        {
          refreshUrl: `${process.env.REACT_APP_STRIPE_REDIRECT}/${username}`,
          returnUrl: `${process.env.REACT_APP_STRIPE_REDIRECT}/${username}`,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ffm_token')}`,
          },
        }
      )
      .then((response) => {
        location.href = response.data.url;
      })
      .catch((error) => {
        toast.update(id, {
          render:
            'There was a problem sending you to Stripe. Please try again later.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });
  }, [username]);

  const toStripeDashboard = useCallback(() => {
    const id = toast.loading('Sending you to Stripe...');

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/stripe-link/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ffm_token')}`,
        },
      })
      .then((response) => {
        location.href = response.data.url;
      })
      .catch((error) => {
        if (error.response.data.errorType === 'STRIPE_SETUP_REQUIRED') {
          toast.update(id, {
            render:
              'You need to complete Stripe registration before you can see your dashboard.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          toast.update(id, {
            render:
              'There was a problem sending you to your dashboard. Please try again later.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
      });
  }, []);

  const [authUser, setAuthUser] = React.useState<
    | {
        username: string;
        currency: string;
        isStripeSetupComplete: boolean;
        notAcceptingPaymentsReasons: string[];
        getStripeLink: () => void;
        toStripeDashboard: () => void;
      }
    | undefined
  >(
    token !== null && username !== null && currency !== null
      ? {
          username: username,
          currency: currency,
          isStripeSetupComplete: isStripeSetupComplete,
          notAcceptingPaymentsReasons: notAcceptingPaymentsReasons,
          getStripeLink: getStripeLink,
          toStripeDashboard: toStripeDashboard,
        }
      : undefined
  );

  useEffect(() => {
    if (isFirstPageLoad && userID !== null) {
      setIsFirstPageLoad(false);

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${userID}`)
        .then((response) => {
          setIsStripeSetupComplete(
            response.data.acceptingPayments &&
              response.data.acceptingPaymentsOptIn
          );
          setAuthUser({
            username: username !== null ? username : '',
            currency: currency !== null ? currency : '',
            isStripeSetupComplete:
              response.data.acceptingPayments &&
              response.data.acceptingPaymentsOptIn,
            notAcceptingPaymentsReasons:
              response.data.notAcceptingPaymentsReasons,
            getStripeLink: getStripeLink,
            toStripeDashboard: toStripeDashboard,
          });
        });
    }
  }, [
    userID,
    authUser,
    username,
    currency,
    getStripeLink,
    toStripeDashboard,
    notAcceptingPaymentsReasons,
    isFirstPageLoad,
  ]);

  const acceptCookies = () => {
    localStorage.setItem('ffm_cookies', 'all');
    localStorage.setItem('ffm_cookies_date', new Date().toISOString());
    setCookies('all');
  };

  const acceptNecessaryCookies = () => {
    localStorage.setItem('ffm_cookies', 'necessary');
    localStorage.setItem('ffm_cookies_date', new Date().toISOString());
    setCookies('necessary');
  };

  const login = (
    t: string,
    uid: string,
    un: string,
    c: string,
    sts: string,
    nap: string[]
  ) => {
    setToken(t);
    setUserID(uid);
    setUsername(un);
    setCurrency(c);
    setIsStripeSetupComplete(sts === 'true' ? true : false);
    setAuthUser({
      username: un,
      currency: c,
      isStripeSetupComplete: sts === 'true' ? true : false,
      notAcceptingPaymentsReasons: nap,
      getStripeLink: getStripeLink,
      toStripeDashboard: toStripeDashboard,
    });

    localStorage.setItem('ffm_token', t);
    localStorage.setItem('ffm_user_id', uid);
    localStorage.setItem('ffm_username', un);
    localStorage.setItem('ffm_currency', c);
  };

  const logout = () => {
    setToken(null);
    setUsername(null);
    setUserID(null);

    setAuthUser(undefined);

    localStorage.removeItem('ffm_token');
    localStorage.removeItem('ffm_user_id');
    localStorage.removeItem('ffm_username');

    location.href = '/login';
  };

  const value = {
    token,
    userID,
    username,
    currency,
    authUser,
    cookies,
    acceptCookies,
    acceptNecessaryCookies,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
