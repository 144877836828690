import React, { useEffect, useState } from 'react';
import { FansFundMeRegisterProfileScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import validate from '../../../utilities/validation';
import axios from '../../../api/axios';
import useAuth from '../../../contexts/UseAuth';
import { toast } from 'react-toastify';
import TOAST_MESSAGES from '../../../utilities/toast-messages';

export const RegisterProfile = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const userID = localStorage.getItem('ffm_user_id');

  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [bio, setBio] = useState('');
  const [bioError, setBioError] = useState('');
  const [profilePicture, setProfilePicture] = useState(
    `https://fans-fund-me-core-user-images-${process.env.REACT_APP_ENVIRONMENT}.s3.eu-west-2.amazonaws.com/profile_pic/FFM-default-profile-picture.png`
  );
  const [coverImage, setCoverImage] = useState(
    `https://fans-fund-me-core-user-images-${process.env.REACT_APP_ENVIRONMENT}.s3.eu-west-2.amazonaws.com/cover_image/FFM-default-cover-image.png`
  );
  const [isLoading, setIsLoading] = useState(false);

  const [stencilWidth, setStencilWidth] = useState<number>(300);
  const [stencilHeight, setStencilHeight] = useState<number>(300);
  const [imageToCrop, setImageToCrop] = useState<string>('');
  const [croppedImageURL, setCroppedImageURL] = useState<string | Blob>('');
  const [uploadingImage, setUploadingImage] = useState('');
  const [cropImageError, setCropImageError] = useState('');
  const [isModalVisible, setIsModalVisible] = useState<boolean | 'crop-image'>(
    false
  );

  // const blobToBase64 = (blob: Blob): Promise<string> => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   return new Promise((resolve) => {
  //     reader.onloadend = () => {
  //       if (typeof reader.result === 'string') {
  //         const str = reader.result.substring(
  //           reader.result.indexOf('base64,') + 7
  //         );
  //         resolve(str);
  //       }
  //     };
  //   });
  // };

  // const uploadCoverImage = (picture: Blob) => {
  //   blobToBase64(picture).then((res) => {
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
  //         {
  //           coverImage: res,
  //         },
  //         { headers: { Authorization: `Bearer ${auth.token}` } }
  //       )
  //       .then(() => {
  //         setCoverImage(URL.createObjectURL(picture));
  //       })
  //       .catch((err) => setDisplayNameError(err.error));
  //   });
  // };

  // const uploadProfilePicture = (picture: Blob) => {
  //   blobToBase64(picture).then((res) => {
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
  //         {
  //           profilePic: res,
  //         },
  //         { headers: { Authorization: `Bearer ${auth.token}` } }
  //       )
  //       .then(() => {
  //         setProfilePicture(URL.createObjectURL(picture));
  //       })
  //       .catch((err) => setDisplayNameError(err.error));
  //   });
  // };

  const chooseNewCoverImage = (picture: Blob) => {
    setStencilWidth(600);
    setStencilHeight(180);
    setImageToCrop(URL.createObjectURL(picture));
    setIsModalVisible('crop-image');
    setUploadingImage('cover-image');
    setCropImageError('');
  };

  const uploadCoverImage = (croppedImageDataURL: string) => {
    const id = toast.loading(TOAST_MESSAGES.IMAGE_UPLOADING('cover image'));
    const newCoverImage = croppedImageDataURL.substring(
      croppedImageDataURL.indexOf('base64,') + 7
    );

    if (newCoverImage === '') {
      setCropImageError('Please change the zoom level on the image');
      toast.update(id, {
        render: TOAST_MESSAGES.IMAGE_ERROR('cover image'),
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });

      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          coverImage: newCoverImage,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        setCoverImage(croppedImageDataURL);
        setIsModalVisible(false);
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_SUCCESS('Cover image'),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setCropImageError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'coverImage'
          )?.message
        );
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_ERROR('cover image'),
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const chooseNewProfilePicture = (picture: Blob) => {
    setStencilWidth(300);
    setStencilHeight(300);
    setImageToCrop(URL.createObjectURL(picture));
    setIsModalVisible('crop-image');
    setUploadingImage('profile-picture');
    setCropImageError('');
  };

  const uploadProfilePicture = (croppedImageDataURL: string) => {
    const id = toast.loading(TOAST_MESSAGES.IMAGE_UPLOADING('profile picture'));
    const newProfilePicture = croppedImageDataURL.substring(
      croppedImageDataURL.indexOf('base64,') + 7
    );

    if (newProfilePicture === '') {
      setCropImageError('Please change the zoom level on the image');
      toast.update(id, {
        render: TOAST_MESSAGES.IMAGE_ERROR('profile picture'),
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });

      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          profilePic: newProfilePicture,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        setProfilePicture(croppedImageDataURL);
        setIsModalVisible(false);
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_SUCCESS('Profile picture'),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setCropImageError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'profilePic'
          )?.message
        );
        toast.update(id, {
          render: TOAST_MESSAGES.IMAGE_ERROR('profile picture'),
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem('ffm_register_username') === null) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    setDisplayNameError('');
  }, [displayName]);

  const handleRegisterProfile = () => {
    setIsLoading(true);

    if (validate('display name', displayName, setDisplayNameError)) {
      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${userID}`,
          {
            displayName: displayName,
            bio: bio,
          },
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then(() => {
          localStorage.removeItem('ffm_register_username');
          localStorage.removeItem('ffm_register_country');
          navigate('/register/stripe');
        })
        .catch((error) => {
          setDisplayNameError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'displayName'
            )?.message
          );
          setBioError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'bio'
            )?.message
          );
        });
    }

    setIsLoading(false);
  };

  const uploadImage = (croppedImageDataURL: string) => {
    switch (uploadingImage) {
      case 'profile-picture':
        uploadProfilePicture(croppedImageDataURL);
        break;

      case 'cover-image':
        uploadCoverImage(croppedImageDataURL);
        break;

      default:
        break;
    }
  };

  return (
    <FansFundMeRegisterProfileScreen
      displayName={displayName}
      setDisplayName={setDisplayName}
      bio={bio}
      setBio={setBio}
      profilePicture={profilePicture}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      setSelectedProfilePicture={chooseNewProfilePicture}
      coverImage={coverImage}
      setSelectedCoverImage={chooseNewCoverImage}
      onClickFinish={handleRegisterProfile}
      displayNameError={displayNameError}
      bioError={bioError}
      isLoading={isLoading}
      uploadProfilePicture={uploadImage}
      stencilWidth={stencilWidth}
      stencilHeight={stencilHeight}
      imageURL={imageToCrop}
      setCroppedImageURL={setCroppedImageURL}
      cropImageError={cropImageError}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
