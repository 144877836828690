import { FansFundMeAccountSettingsScreen } from '@fans-fund-me/storybook';
import React, { useEffect, useState } from 'react';
import useAuth from '../../contexts/UseAuth';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import validate from '../../utilities/validation';
import { toast } from 'react-toastify';
import TOAST_MESSAGES from '../../utilities/toast-messages';
import currencies from '../../utilities/currencies.json';

export const AccountSettings = (): React.ReactElement => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [userCurrency, setUserCurrency] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<
    | boolean
    | 'currency'
    | 'password'
    | 'delete-account'
    | 'email-address'
    | 'surprise-gift-minimum-amount'
  >(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [displayOnPublicLeaderboard, setDisplayOnPublicLeaderboard] =
    useState(false);
  const [emailProductUpdates, setEmailProductUpdates] = useState(false);
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState(false);
  const [giftInformationInEmails, setGiftInformationInEmails] = useState(false);
  const [profileCurrency, setProfileCurrency] = useState<{
    label: string | undefined;
    value: string | undefined;
  }>({ label: undefined, value: undefined });
  const [enableSurpriseGifts, setEnableSurpriseGifts] = useState(false);
  const [surpriseGiftMiniumAmount, setSurpriseGiftMiniumAmount] = useState('');
  const [enableAutomaticTweeting, setEnableAutomaticTweeting] = useState(false);
  const [emailAddressError, setEmailAddressError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [displayCurrencyError, setDisplayCurrencyError] = useState('');
  const [surpriseGiftMinimumAmountError, setSurpriseGiftMinimumAmountError] =
    useState('');
  const [deleteAccountError, setDeleteAccountError] = useState('');
  const [savedEmailAddress, setSavedEmailAddress] = useState('');
  const [savedSurpriseGiftMiniumAmount, setSavedSurpriseGiftMiniumAmount] =
    useState('');

  // const updateUserCurrency = (currency: string) => {
  //   setUserCurrency(currency);
  //   localStorage.setItem('ffm_currency', currency);
  // };

  useEffect(() => {
    const currency = localStorage.getItem('ffm_currency');

    if (currency !== null) {
      setUserCurrency(currency);
    }
  }, [setUserCurrency]);

  useEffect(() => {
    setIsLoading(true);
    const userID = localStorage.getItem('ffm_user_id');

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${userID}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        if (response.data.email === '') {
          auth.logout();
        }

        setProfileCurrency({
          label: response.data.currency,
          value: response.data.currency,
        });
        setEmailAddress(response.data.email);
        setSavedEmailAddress(response.data.email);
        setEmailProductUpdates(response.data.emailProductUpdates);
        setDisplayOnPublicLeaderboard(response.data.leaderboardOptIn);
        setEmailNotificationsEnabled(response.data.emailGiftNotification);
        setGiftInformationInEmails(response.data.emailIncludeGiftInformation);
        setEnableSurpriseGifts(response.data.enableSurpriseGifts);
        setSurpriseGiftMiniumAmount(response.data.surpriseGiftsMinimum);
        setSavedSurpriseGiftMiniumAmount(response.data.surpriseGiftsMinimum);

        setIsLoading(false);
      })
      .catch(() => {
        // TODO - If Profile isn't found (404 response), log user out
        toast.error('There was a problem getting your profile information');
      });

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_X_AUTH}/user/${userID}`)
      .then((response) => {
        setEnableAutomaticTweeting(response.data.isEnabled);
      });

    //   axios
    //     .get(
    //       `${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/user/${userID}`,
    //       { headers: { Authorization: `Bearer ${auth.token}` } }
    //     )
    //     .then((response) => {
    //       setEmailAddress(response.data.email);
    //       setSavedEmailAddress(response.data.email);
    //     })
    //     .catch(() => {});
  }, [auth, auth.token]);

  useEffect(() => {
    setCurrentPassword('');
    setPassword('');
    setConfirmPassword('');
    // setEmailAddress('');

    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setEmailAddressError('');
    setDisplayCurrencyError('');
    setSurpriseGiftMinimumAmountError('');
    setDeleteAccountError('');

    if (isModalVisible === false) {
      setEmailAddress(savedEmailAddress);
      setProfileCurrency({
        label: userCurrency,
        value: userCurrency,
      });
      setSurpriseGiftMiniumAmount(
        savedSurpriseGiftMiniumAmount === null
          ? ''
          : savedSurpriseGiftMiniumAmount
      );
    }
  }, [
    isModalVisible,
    savedEmailAddress,
    userCurrency,
    savedSurpriseGiftMiniumAmount,
    surpriseGiftMiniumAmount,
  ]);

  const onClickSaveEmailAddress = () => {
    const userID = localStorage.getItem('ffm_user_id');

    if (validate('email', emailAddress, setEmailAddressError)) {
      axios
        .patch(
          `${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/user/${userID}`,
          {
            email: emailAddress,
          },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        )
        .then(() => {
          setIsModalVisible(false);
          setEmailAddress(emailAddress);
          setSavedEmailAddress(emailAddress);
        })
        .catch((error) => {
          setEmailAddressError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'email'
            )?.message
          );
        });
    }
  };

  const onClickSavePassword = () => {
    if (
      validate('current password', currentPassword, setCurrentPasswordError) &&
      validate('new password', password, setNewPasswordError) &&
      validate(
        'confirm password',
        confirmPassword,
        setConfirmPasswordError,
        'match',
        'password',
        password
      )
    ) {
      axios
        .patch(
          `${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/user?updateType=password`,
          {
            email: emailAddress,
            oldPassword: currentPassword,
            newPassword: password,
          },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        )
        .then(() => {
          setIsModalVisible(false);
        })
        .catch((error) => {
          setNewPasswordError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'password'
            )?.message
          );
          setConfirmPasswordError('');
        });
    }
  };

  const onClickSetupTwitter = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_X_AUTH}/user/${auth.userID}`,
        {},
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then((response) => (location.href = response.data.url));
  };

  const onClickRemoveTwitter = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT_X_AUTH}/user/${auth.userID}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then((response) => {
        setEnableAutomaticTweeting(false);
      });
  };

  const onClickSaveCurrency = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          currency: profileCurrency.value,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        // const UD = userDetails;
        // UD.currency = profileCurrency.value;
        // setUserDetails(UD);
        setProfileCurrency({
          label: profileCurrency.value,
          value: profileCurrency.value,
        });

        setUserCurrency(profileCurrency.value!);
        localStorage.setItem('ffm_currency', profileCurrency.value!);
        auth.authUser = {
          username: auth.authUser !== undefined ? auth.authUser.username : '',
          currency: profileCurrency.value!,
          isStripeSetupComplete:
            auth.authUser !== undefined
              ? auth.authUser.isStripeSetupComplete
              : false,
          getStripeLink:
            auth.authUser !== undefined
              ? auth.authUser.getStripeLink
              : () => {},
          notAcceptingPaymentsReasons:
            auth.authUser !== undefined
              ? auth.authUser.notAcceptingPaymentsReasons
              : [],
        };
        setIsModalVisible(false);
      })
      .catch(async (error) => {
        if (error.response.status === 500) {
          setDisplayCurrencyError(
            'There was a problem processing your request. Please try again later'
          );
        } else {
          setDisplayCurrencyError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'currency'
            )?.message
          );
        }
      });
  };

  const updateDisplayOnPublicLeaderboard = async (value: boolean) => {
    setDisplayOnPublicLeaderboard(value);

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          leaderboardOptIn: value, // TODO - SET VALUE
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        toast.success(TOAST_MESSAGES.PUBLIC_LEADERBOARD_TOGGLE_SUCCESS(value));
      })
      .catch(async () => {
        setDisplayOnPublicLeaderboard(!value);
        toast.error(TOAST_MESSAGES.TOGGLE_ERROR('public leaderboard'));
      });
  };

  const updateEmailProductUpdates = (value: boolean) => {
    setEmailProductUpdates(value);

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          emailProductUpdates: value,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        toast.success(
          TOAST_MESSAGES.PRODUCT_UPDATE_EMAILS_TOGGLE_SUCCESS(value)
        );
      })
      .catch(async () => {
        setEmailProductUpdates(!value);
        toast.error(TOAST_MESSAGES.TOGGLE_ERROR('FFM Newsletter'));
      });
  };

  const updateEmailNotifications = (value: boolean) => {
    setEmailNotificationsEnabled(value);

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          emailGiftNotification: value,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        toast.success(TOAST_MESSAGES.GIFT_EMAILS_TOGGLE_SUCCESS(value));
      })
      .catch(async () => {
        setEmailNotificationsEnabled(!value);
        toast.error(TOAST_MESSAGES.TOGGLE_ERROR('email notification'));
      });
  };

  const updateGiftInfoInEmails = (value: boolean) => {
    setGiftInformationInEmails(value);

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          emailIncludeGiftInformation: value, // TODO - SET VALUE
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        toast.success(TOAST_MESSAGES.GIFT_INFO_EMAILS_TOGGLE_SUCCESS(value));
      })
      .catch(async () => {
        setGiftInformationInEmails(!value);
        toast.error(TOAST_MESSAGES.TOGGLE_ERROR('email gift information'));
      });
  };

  const updateEnableSurpriseGifts = (value: boolean) => {
    setEnableSurpriseGifts(value);

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          enableSurpriseGifts: value,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        toast.success(TOAST_MESSAGES.SURPRISE_GIFTS_TOGGLE_SUCCESS(value));
      })
      .catch(async () => {
        setEnableSurpriseGifts(!value);
        toast.error(TOAST_MESSAGES.TOGGLE_ERROR('Surprise Gift'));
      });
  };

  const onClickSaveSurpriseGiftMinimumAmount = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
        {
          surpriseGiftsMinimum: surpriseGiftMiniumAmount,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then((response) => {
        setIsModalVisible(false);
        setSurpriseGiftMiniumAmount(response.data.surpriseGiftsMinimum);
        setSavedSurpriseGiftMiniumAmount(response.data.surpriseGiftsMinimum);
      })
      .catch(async (error) => {
        setSurpriseGiftMinimumAmountError(
          error.response.data.fields?.find(
            (field: { fieldName: string; message: string }) =>
              field.fieldName === 'surpriseGiftsMinimum'
          )?.message
        );
      });
  };

  const onClickDeleteAccount = () => {
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/user`, {
        email: emailAddress,
        password: password,
      })
      .then(() => {
        axios
          .delete(
            `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/profile/${auth.userID}`,
            { headers: { Authorization: `Bearer ${auth.token}` } }
          )
          .then(() => {
            auth.logout();
            setIsModalVisible(false);
            navigate('/');
          })
          .catch((error) => {
            if (error.response.status === 500) {
              setDeleteAccountError(
                'There was a problem processing your request. Please try again later'
              );
            }
          });
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setDeleteAccountError(
            'There was a problem processing your request. Please try again later'
          );
        } else {
          setDeleteAccountError(
            error.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'password'
            )?.message
          );
        }
      });
  };

  const onClickLogout = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/token/${auth.token}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      .then(() => {
        auth.logout();
      })
      .catch(() => {
        auth.logout();
      });
  };

  const onClickLogoutEverywhere = () => {
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION}/token`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then(() => {
        auth.logout();
      })
      .catch(() => {
        auth.logout();
      });
  };

  return (
    <FansFundMeAccountSettingsScreen
      navbarItems={[]}
      authUser={
        auth.authUser !== undefined
          ? auth.authUser
          : {
              username: '',
              currency: '',
              isStripeSetupComplete: false,
              notAcceptingPaymentsReasons: [],
            }
      }
      acceptedCurrencies={currencies}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      currentPassword={currentPassword}
      setCurrentPassword={setCurrentPassword}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      displayOnPublicLeaderboard={displayOnPublicLeaderboard}
      setDisplayOnPublicLeaderboard={updateDisplayOnPublicLeaderboard}
      enableAutomaticTweeting={enableAutomaticTweeting}
      setEnableAutomaticTweeting={
        enableAutomaticTweeting ? onClickRemoveTwitter : onClickSetupTwitter
      }
      emailProductUpdates={emailProductUpdates}
      setEmailProductUpdates={updateEmailProductUpdates}
      emailNotificationsEnabled={emailNotificationsEnabled}
      setEmailNotificationsEnabled={updateEmailNotifications}
      giftInformationInEmails={giftInformationInEmails}
      setGiftInformationInEmails={updateGiftInfoInEmails}
      enableSurpriseGifts={enableSurpriseGifts}
      setEnableSurpriseGifts={updateEnableSurpriseGifts}
      surpriseGiftMinimumAmount={surpriseGiftMiniumAmount}
      setSurpriseGiftMinimumAmount={setSurpriseGiftMiniumAmount}
      onClickSaveSurpriseGiftMinimumAmount={
        onClickSaveSurpriseGiftMinimumAmount
      }
      isLoading={isLoading}
      onClickSaveEmailAddress={onClickSaveEmailAddress}
      onClickSavePassword={onClickSavePassword}
      onClickSaveCurrency={onClickSaveCurrency}
      onClickDeleteAccount={onClickDeleteAccount}
      onClickLogout={onClickLogout}
      onClickLogoutEverywhere={onClickLogoutEverywhere}
      profileCurrency={profileCurrency}
      setProfileCurrency={(
        label: string | undefined,
        value: string | undefined
      ) => setProfileCurrency({ label: label, value: value })}
      emailAddressError={emailAddressError}
      currentPasswordError={currentPasswordError}
      newPasswordError={newPasswordError}
      confirmPasswordError={confirmPasswordError}
      displayCurrencyError={displayCurrencyError}
      deleteAccountError={deleteAccountError}
      surpriseGiftMinimumAmountError={surpriseGiftMinimumAmountError}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
