import { FansFundMeMarkdownDocumentScreen } from '@fans-fund-me/storybook';
import useAuth from '../../contexts/UseAuth';
import { useEffect, useState } from 'react';
import currencies from '../../utilities/currencies.json';
import prohibitedWords from './prohibited-words-01032025.md';

export const ProhibitedWords = () => {
  const auth = useAuth();
  const [policy, setPolicy] = useState<string>('');

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null
      ? auth.currency
      : localStorage.getItem('ffm_currency') !== null
      ? localStorage.getItem('ffm_currency')
      : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  useEffect(() => {
    fetch(prohibitedWords)
      .then((response) => response.text())
      .then((text) => {
        setPolicy(text);
      });
  }, []);

  return (
    <FansFundMeMarkdownDocumentScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency!}
      setUserCurrency={updateUserCurrency}
      title='Prohibited Words'
      subtitle='Last updated: 1 March 2025'
      content={policy}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
